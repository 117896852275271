import { useTheme } from '@mui/material'
import { type FC, useEffect, useState } from 'react'
import { Authentication } from '@modules/authentication'
import { UserWorkspaceComponent, type IResponse } from '@ntpkunity/controls-ums'

import queryString from 'query-string'
import { useNavigate } from 'react-router-dom'
import { APP_ROUTES } from '@router/path'
import { clearLocalStorage, getItemByKey, isLoggedIn } from '@shared/helper'
import {
  HttpStatus,
  errorTitle,
  expiredEmailLinkButtonText,
  expiredEmailLinkSubTitle,
  expiredLinkTypes,
  className,
  CURRENT_ROUTE
} from '@shared/constants'

export const AppUserProfile: FC = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { tab: token } = queryString.parse(location?.search) as any
  const [response, setResponse] = useState<IResponse>()
  const [urlValidateResponse, setUrlValidateResponse] = useState()

  if ((isLoggedIn() ?? '').length > 0) {
    navigate(getItemByKey(CURRENT_ROUTE))
  }

  if (!token) {
    navigate(APP_ROUTES.SIGNUP, {
      replace: true
    })
  }

  useEffect(() => {
    if (response !== undefined) {
      const { status } = response
      if (status === HttpStatus.Bad_Request) {
        navigate(APP_ROUTES.EMAIL_LINK_EXPIRED, {
          replace: true,
          state: {
            token,
            verificationScreenUrl: `${APP_ROUTES.APP_USER_PROFILE}`,
            type: expiredLinkTypes.verfication,
            layoutText: {
              title: errorTitle,
              subTitle: expiredEmailLinkSubTitle,
              buttonText: expiredEmailLinkButtonText,
              seoButtonText: className.userProfileContinueBtn
            }
          }
        })
      }
      if (status === HttpStatus.OK) {
        clearLocalStorage()
        navigate(APP_ROUTES.SIGNUP, {
          replace: true
        })
      }
    }
  }, [response])

  return (
    <Authentication>
      <UserWorkspaceComponent
        theme={theme}
        validateUrl={token}
        setResponse={setResponse}
        setUrlValidateResponse={setUrlValidateResponse}
      />
    </Authentication>
  )
}
