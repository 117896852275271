import { type FC } from 'react'
import { useTheme } from '@mui/material'
import { Grid, Typography, Box } from '@ntpkunity/controls'
import { MultiColorCard } from '@shared/components'
import { EcosystemSectionStyle } from './ecosystemSectionStyle'

export const EcosystemSection: FC = () => {
  const theme = useTheme()
  return (
    <EcosystemSectionStyle theme={theme} className='ecosystem-section'>
            <Typography theme={theme} variant='h1' component='h1' className='heading-lg' textAlign={'center'} mb={{ md: 12, sm: 4, xs: 4 }}>
                AI Applications Ecosystem
            </Typography>
            <Grid theme={theme} container rowSpacing={{ md: 10, sm: 5, xs: 5 }} columnSpacing={{ md: 10, sm: 2, xs: 2 }} alignItems={'center'}>
                <Grid theme={theme} item md={6} sm={12} xs={12}>
                 <Typography theme={theme} variant='h2' component='h2' className='heading-sm' mb={{ md: 4, sm: 1, xs: 1 }}>
                 Deep Reasoning Research
                 </Typography>
                 <Typography theme={theme} variant='body1' component='p' className='section-text'>
                    Uses AI-driven reasoning to analyse applicants and their asset to generate detailed insightful reports
                 </Typography>
                </Grid>
                <Grid theme={theme} item md={6} sm={12} xs={12}>
                    <Grid theme={theme} container rowSpacing={2} columnSpacing={2} alignItems={'center'}>
                        <Grid theme={theme} item sm={6} xs={12}>
                            <MultiColorCard
                                purple
                                title='SWOT Analysis'
                                text='AI-driven SWOT analysis that evaluates applicant strengths, weaknesses, opportunities, and threats by integrating financial, risk, and economic data for informed credit decisions.'
                            />
                        </Grid>
                        <Grid theme={theme} item sm={6} xs={12}>
                        <Box theme={theme} mb={2}>
                            <MultiColorCard
                                green
                                title='Application Insights'
                                text='Aggregates applicant financial history, job stability, income trends, and liabilities to present a comprehensive risk profile and repayment capability.'
                            />
                            </Box>
                            <MultiColorCard
                                orange
                                title='Asset Insights'
                                text='Provides real-time insights into asset valuation trends, resale values, and depreciation rates to enhance risk assessment and finance structuring.'
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {/* **************************************** */}
                <Grid theme={theme} item md={6} sm={12} xs={12} display={{ xl: 'none', lg: 'none', md: 'none', sm: 'inline-block', xs: 'inline-block' }}>
                 <Typography theme={theme} variant='h2' component='h2' className='heading-sm' mb={{ sm: 1, xs: 1 }}>
                 Intelligent Document Processing
                 </Typography>
                 <Typography theme={theme} variant='body1' component='p' className='section-text'>
                 Automates document handling, including extraction, classification, and analysis, to enhance efficiency
                 </Typography>
                </Grid>
                <Grid theme={theme} item md={6} sm={12} xs={12}>
                    <Grid theme={theme} container rowSpacing={2} columnSpacing={2} alignItems={'center'}>
                        <Grid theme={theme} item sm={6} xs={12}>
                            <MultiColorCard
                                purple
                                title='Document Analysis'
                                text='Advanced AI-driven analysis that interprets extracted document data, detects anomalies, verifies compliance, and provides contextual insights for decision-making.'
                            />
                        </Grid>
                        <Grid theme={theme} item sm={6} xs={12}>
                            <Box theme={theme} mt={{ md: 18, sm: 0, xs: 0 }}>
                                <MultiColorCard
                                    green
                                    title='Document Extraction'
                                    text='AI-powered extraction of key data points from structured and unstructured documents, enabling seamless digitization and automation of manual data entry.'
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid theme={theme} item md={6} sm={12} xs={12} display={{ xl: 'inline-block', lg: 'inline-block', md: 'inline-block', sm: 'none', xs: 'none' }}>
                 <Typography theme={theme} variant='h2' component='h2' className='heading-sm' mb={{ md: 4 }}>
                 Intelligent Document<br /> Processing
                 </Typography>
                 <Typography theme={theme} variant='body1' component='p' className='section-text'>
                 Automates document handling, including extraction, classification, and analysis, to enhance efficiency
                 </Typography>
                </Grid>
                {/* **************************************** */}
                <Grid theme={theme} item md={6} sm={12} xs={12}>
                 <Typography theme={theme} variant='h2' component='h2' className='heading-sm' mb={{ md: 4, sm: 1, xs: 1 }}>
                 Flex AI
                 </Typography>
                 <Typography theme={theme} variant='body1' component='p' className='section-text'>
                 A flexible AI powered finance structure recommender powered by generative AI and Flex
                 </Typography>
                </Grid>
                <Grid theme={theme} item md={6} sm={12} xs={12}>
                <Grid theme={theme} container rowSpacing={2} columnSpacing={2} alignItems={'center'}>
                        <Grid theme={theme} item sm={6} xs={12}>
                            <MultiColorCard
                                orange
                                title='Finance Structure Recommendation'
                                text='Suggests optimal finance structures based on applicant data, risk metrics, and industry benchmarks to support well-informed credit decisions.'
                            />
                        </Grid>
                        <Grid theme={theme} item sm={6} xs={12}>
                            <Box theme={theme} mt={{ md: 18, sm: 0, xs: 0 }}>
                                <MultiColorCard
                                    green
                                    title='Stipulation Generation'
                                    text='Automatically detects discrepancies and generates lender-specific stipulations, ensuring compliance and mitigating risk in credit approvals.'
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                {/* **************************************** */}
                <Grid theme={theme} item md={6} sm={12} xs={12} display={{ xl: 'none', lg: 'none', md: 'none', sm: 'inline-block', xs: 'inline-block' }}>
                 <Typography theme={theme} variant='h2' component='h2' className='heading-sm' mb={{ sm: 1, xs: 1 }}>
                 Rule Forge
                 </Typography>
                 <Typography theme={theme} variant='body1' component='p' className='section-text'>
                 A platform for creating and validating business rules using AI-driven logic for automated decision-making
                 </Typography>
                </Grid>
                <Grid theme={theme} item md={6} sm={12} xs={12}>
                    <MultiColorCard
                        orange
                        title='Natural Language Business Rules Generation & Validations'
                        text='Transforms natural language inputs into structured, executable business rules without coding while ensuring accuracy through automated validation against logic, data, and compliance standards.'
                    />
                </Grid>
                <Grid theme={theme} item md={6} sm={12} xs={12} display={{ xl: 'inline-block', lg: 'inline-block', md: 'inline-block', sm: 'none', xs: 'none' }}>
                 <Typography theme={theme} variant='h2' component='h2' className='heading-sm' mb={{ md: 4 }}>
                 Rule Forge
                 </Typography>
                 <Typography theme={theme} variant='body1' component='p' className='section-text'>
                 A platform for creating and validating business rules using AI-driven logic for automated decision-making
                 </Typography>
                </Grid>
                {/* **************************************** */}
                <Grid theme={theme} item md={6} sm={12} xs={12}>
                 <Typography theme={theme} variant='h2' component='h2' className='heading-sm' mb={{ md: 4, sm: 1, xs: 1 }}>
                 Chat
                 </Typography>
                 <Typography theme={theme} variant='body1' component='p' className='section-text'>
                 AI-powered conversational interface enabling real-time customer engagement and support
                 </Typography>
                </Grid>
                <Grid theme={theme} item md={6} sm={12} xs={12}>
                    <MultiColorCard
                        green
                        title='Contextual awareness & Tool Calling '
                        text='Chat that understands conversational context for personalized interactions while seamlessly integrating with internal and external APIs to execute tasks, enhancing efficiency and user experience.'
                    />
                </Grid>
                {/* **************************************** */}
                <Grid theme={theme} item md={6} sm={12} xs={12} display={{ xl: 'none', lg: 'none', md: 'none', sm: 'inline-block', xs: 'inline-block' }}>
                 <Typography theme={theme} variant='h2' component='h2' className='heading-sm' mb={{ sm: 1, xs: 1 }}>
                 Data-Driven Decisions
                 </Typography>
                 <Typography theme={theme} variant='body1' component='p' className='section-text'>
                 Machine learning models for predictive analysis and forecasting
                 </Typography>
                </Grid>
                <Grid theme={theme} item md={6} sm={12} xs={12}>
                    <Grid theme={theme} container rowSpacing={2} columnSpacing={2} alignItems={'center'}>
                        <Grid theme={theme} item sm={6} xs={12}>
                            <MultiColorCard
                                orange
                                title='Likelihood of Defaults'
                                text='Predictive modelling that assesses financial risk by analysing applicant credit history, income trends, and market conditions to estimate the probability of loan default.'
                            />
                        </Grid>
                        <Grid theme={theme} item sm={6} xs={12}>
                        <MultiColorCard
                                    purple
                                    title='Accessories Recommendations'
                                    text='Personalized recommendations for vehicle accessories, leveraging customer preferences, purchase trends, and vehicle specifications to drive upsell opportunities.'
                                />
                        </Grid>
                        <Grid theme={theme} item xs={12}>
                        <MultiColorCard
                                    pink
                                    title='Business KPIs forecasting'
                                    text='Predictive analytics that utilizes historical data and market trends to project key business performance indicators, supporting strategic planning and decision-making.'
                                />
                        </Grid>
                        <Grid theme={theme} item sm={6} xs={12}>
                        <MultiColorCard
                                    green
                                    title='Customer Clustering'
                                    text='Machine learning-powered segmentation that groups customers based on behavioral, financial, and demographic patterns to enhance targeted marketing and risk assessment strategies.'
                                />
                        </Grid>
                        <Grid theme={theme} item sm={6} xs={12}>
                        <MultiColorCard
                                    green
                                    title='F&I Product Recommendations'
                                    text='Recommendation engine that suggests optimal finance and insurance (F&I) products based on customer profiles, purchase history, and risk assessment.'
                                />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid theme={theme} item md={6} sm={12} xs={12} display={{ xl: 'inline-block', lg: 'inline-block', md: 'inline-block', sm: 'none', xs: 'none' }}>
                 <Typography theme={theme} variant='h2' component='h2' className='heading-sm' mb={{ md: 4 }}>
                 Data-Driven Decisions
                 </Typography>
                 <Typography theme={theme} variant='body1' component='p' className='section-text'>
                 Machine learning models for predictive analysis and forecasting
                 </Typography>
                </Grid>
            </Grid>
          </EcosystemSectionStyle>
  )
}
