import { APP_ROUTES } from '@router/path'
import { CardType } from './enums'

export const errorStatusCodes = [401, 400, 403, 404, 409, 500, 501, 502]
export const markAsDefault = 'MARK AS DEFAULT'
export const markingAsDefault = 'MARKING AS DEFAULT'
export const CUSTOM_KEY = 'custom_!@#$mktplc'

export const pathsToExclude = [
  APP_ROUTES.SIGNUP,
  APP_ROUTES.LOGIN,
  APP_ROUTES.OTP,
  APP_ROUTES.USER_PROFILE,
  APP_ROUTES.EMAIL_LINK_EXPIRED,
  APP_ROUTES.EMAIL_VERIFICATION,
  APP_ROUTES.APP_USER_PROFILE,
  APP_ROUTES.RESEND_PASSWORD_LINK,
  APP_ROUTES.RESET_PASSWORD,
  APP_ROUTES.FORGOT_PASSWORD
]

export const protectedRoutesAllowedToNonAdmin = [
  APP_ROUTES.SINGLE_SIGN_ON
]

export const offices = [
  'Calabasas +1 818 222 9195',
  'London +44 2 0398 10290',
  'Sydney +61 2 9159 0165',
  'Beijing +86 10 6568 2256',
  'Shanghai +86 21 6020 6700',
  'Bangkok +66 2685 3552 3',
  'Jakarta +62 21 2965 5859',
  'Lahore +92 42 111 44 88 00'
]
export const dateFormat = 'MMM DD, YYYY'
export const formatDate = 'DD, MMM YYYY'
export const fieldLengthLimit = 50
export const tabName = {
  '/market-place/signup': 'Get Started',
  '/market-place/otp': 'OTP',
  '/market-place/payment-plan': 'Payment Plan',
  '/market-place/package-list': 'Packages',
  '/market-place/contact-us': 'Contact Us - AppexNow',
  '/market-place/privacy-policy': 'Privacy Policy',
  '/market-place/update-payment': 'Update Payment',
  '/market-place/terms-and-conditions': 'Terms and Conditions',
  '/market-place/documentation': 'Documentation',
  '/market-place/terms-of-sevices': 'AppexNow - Terms of Sevices',
  '/market-place/dashboard': 'Dashboard',
  '/market-place/profile': 'Profile',
  '/market-place/my-account': 'My Account',
  '/market-place/billing-details': 'Billing Details',
  '/market-place/transaction-history': 'Transaction History',
  '/market-place/customer-account': 'Subscriber Account',
  '/market-place/create-user': 'User List',
  '/market-place/email-preferences': 'Email Preferences',
  '/': 'Marketplace Of API-First Products For Finance & Leasing Industry | AppexNow',
  '/market-place/products': 'AppexNow - Explore Our Products',
  '/market-place/services': 'Services',
  '/market-place/add-ons': 'Add-Ons',
  '/market-place/bundles': 'Bundles',
  '/market-place/product': 'Product',
  '/market-place/email-verification': 'Email verification',
  '/market-place/user-profile': 'User Profile',
  '/market-place/app-user-profile': 'User Profile',
  '/market-place/tenant-profile': 'Tenant Profile',
  '/market-place/forgot-password': 'Fogot Password',
  '/market-place/change-password': 'Change Password',
  '/market-place/email-link-expired': 'Email Link Expired',
  '/market-place/resend-password-link': 'Resend Password Link',
  '/market-place/reset-password': 'Reset Password',
  '/market-place/terms-cond-agreement': 'Terms and Cond',
  '/market-place/login': 'Login',
  '/market-place/allowed-products': 'Allowed Products Page',
  '/market-place/track-sign-up-users': 'Users',
  '/market-place/marketing-links': 'Links',
  '/market-place/track-ums-users': 'Users',
  '/market-place/checkout': 'Checkout',
  '/market-place/unsubscribe': 'Unsubscribe',
  '/market-place/unsubscription-error': 'Error Page',
  '/market-place/feedback': 'Feedback',
  '/blog': 'Blog',
  '/webinar': 'Webinar'
}

export const products = {
  flex: 'Flex - All In One Calculation Engine For Your Ecosystem',
  index: 'Index - Cloud-Based Master Data Management As A Service',
  dock: 'Dock - Automated Document Generation Tool With Template Based Configuration',
  hubex: 'Hubex - Pre-built Integration Hub With Best-In-Class APIs',
  lane: 'Lane - End To End Order Management System For Finance & Leaisng',
  taxengine: 'Tax Engine - Real-Time Universal Tax Calculations'
}

export const CardIcon = {
  [CardType.VISA]: 'VisaPaymentIcon',
  [CardType.MASTERCARD]: 'MasterCardPaymentIcon'
}

export const filterKeys = {
  full_name: 'Full Name',
  email: 'Email',
  country: 'Country',
  company_name: 'Company Name',
  status: 'Status',
  product_name: 'Product Name',
  allowed_transactions: 'Allowed Transactions',
  transaction_performed: 'Performed Transactions',
  created_at: '',
  api_key_type: 'Key Type'
}
export const packages = ['Basic', 'Standard', 'Premium']
export const FlexPackages = ['Free Trial', 'Custom', ...packages]
export const UserTracking = [
  'Full Name',
  'Email',
  'Product Name',
  'Country',
  'Company Name',
  'Allowed Transactions',
  'Performed Transactions',
  'Status',
  'Created At'
]
export const customPackageDescription = {
  flex: 'Get tailored solutions, volume pricing, and dedicated support for your team.',
  hubex:
    'Get tailored solutions, volume pricing, and dedicated support for your team.',
  index:
    'Get tailored solutions, volume pricing, and dedicated support for your team.',
  dock:
    'Get tailored solutions, volume pricing, and dedicated support for your team.',
  lane:
    'Get tailored solutions, volume pricing, and dedicated support for your team.'
}

export const gotoApp = ['index', 'lane', 'broker portal']

export const expiredLinkTypes = {
  verfication: 'verfication',
  password: 'password'
}
