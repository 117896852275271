import {
  CURRENT_ROUTE,
  pathsToExclude,
  products,
  setItembyKey,
  tabName
} from 'shared'
import { type FC, useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { APP_ROUTES } from './path'
import ProtectedRoute from './ProtectedRoutes'

import {
  CustomerAccount,
  PaymentPlan,
  PrivacyPolicy,
  ContactUs,
  TransactionHistory,
  Dashboard,
  ProfilePage,
  TermsOfService,
  Registration,
  Signin,
  UserList,
  MyAccountPage,
  BillingDetails,
  EmailPrefrencesPage,
  SignleSignOnPage,
  CheckoutPage,
  UnsubscriptionPage,
  UnsubscriptionErrorHandling,
  FeedbackPage,
  BlogListingPage,
  BlogDetailsPage,
  useGetBlogDetails,
  OTP,
  WebinarListingPage,
  WebinarDetailsPage
} from '../modules'
import { DashboardLayout } from '@layout/index'
import { DetailPage, NewHomePage, ListingPage } from '@modules/homePage'
import { EmailVerification } from '@modules/authentication/pages/emailVerification/emailVerification'
import { ForgotPassword } from '@modules/authentication/pages/forgotPassword/forgotPassword'
import { ResetPassword } from '@modules/authentication/pages/resetPassword/resetPassword'
import { ResetPasswordLink } from '@modules/authentication/pages/resetPasswordLink/ResetPasswordLink'
import { UserProfile } from '@modules/authentication/pages/userProfile/userProfile'
import { TenantProfile } from '@modules/authentication/pages/tenantProfile/tenantProfile'
import { ChangePassword } from '@modules/authentication/pages/changePassword/changePassword'
import { AppUserProfile } from '@modules/authentication/pages/appUserProfile/appUserProfile'
import { EmailLinkExpired } from '@modules/authentication/pages/emailLinkExpiration/emailLinkExpiration'
import { TrackUmsUsersPage } from '@modules/trackusers/trackUmsUserPage'

export const Router: FC = () => {
  const { search, pathname } = useLocation()
  const splittedPath = pathname.split('/')
  const productName = splittedPath[splittedPath.length - 1]
  const isBlogOrWebinar = splittedPath.includes('blog') || splittedPath.includes('webinar')
  const blogOrWebinarTitle = isBlogOrWebinar ? productName : ''

  const { data: blogDetail } = useGetBlogDetails(blogOrWebinarTitle ? productName : '')

  useEffect(() => {
    if (!pathsToExclude.includes(pathname)) {
      setItembyKey(CURRENT_ROUTE, pathname + search)
    }
    document.title = isBlogOrWebinar
      ? blogDetail?.meta_title ?? tabName[pathname]
      : pathname === APP_ROUTES.PRODUCT.replace(':productName', productName)
        ? products[
          decodeURIComponent(productName)?.toLowerCase().replace(' ', '')
        ] || decodeURIComponent(productName)
        : tabName[pathname] || decodeURIComponent(productName)
  }, [pathname, productName, blogDetail])

  return (
    <Routes>
      {/* <Route path={APP_ROUTES.HOME_PAGE} element={<HomePage />} /> */}
      <Route path={APP_ROUTES.NEW_HOME_PAGE} element={<NewHomePage />} />
      <Route
        path={APP_ROUTES.UNSUBSCRIPTION_ERROR_PAGE}
        element={<UnsubscriptionErrorHandling />}
      />
      <Route
        path={APP_ROUTES.EMAIL_VERIFICATION}
        element={<EmailVerification />}
      />
      <Route path={APP_ROUTES.OTP} element={<OTP />} />
      <Route path={APP_ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
      <Route path={APP_ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
      <Route
        path={APP_ROUTES.RESEND_PASSWORD_LINK}
        element={<ResetPasswordLink />}
      />
      <Route path={APP_ROUTES.APP_USER_PROFILE} element={<AppUserProfile />} />
      <Route path={APP_ROUTES.USER_PROFILE} element={<UserProfile />} />

      <Route path={APP_ROUTES.PRODUCT} element={<DetailPage />} />
      <Route path={APP_ROUTES.SIGNUP} element={<Registration />} />
      <Route path={APP_ROUTES.LOGIN} element={<Registration />} />
      <Route path={APP_ROUTES.TERMS_OF_SERVICES} element={<TermsOfService />} />
      <Route path={APP_ROUTES.CONTACT_US} element={<ContactUs />} />
      <Route path={APP_ROUTES.PRIVACY_POLICY} element={<PrivacyPolicy />} />
      {/* <Route
        path={APP_ROUTES.TERMS_AND_CONDITION}
        element={
          // <ProtectedRoute>
            <TermsAndConditions />
          // </ProtectedRoute>
        }
      /> */}

      <Route
        path={APP_ROUTES.EMAIL_LINK_EXPIRED}
        element={<EmailLinkExpired />}
      />
      <Route
        path={APP_ROUTES.PROFILE}
        element={
          // <ProtectedRoute>
          <ProfilePage />
          // </ProtectedRoute>
        }
      />
      <Route path={APP_ROUTES.NEW_UMS_USERS} element={<TrackUmsUsersPage />} />
      <Route path={APP_ROUTES.PRODUCTS_LISTING} element={<ListingPage />} />
      <Route path={APP_ROUTES.FEEDBACK} element={<FeedbackPage />} />
      <Route path={APP_ROUTES.BLOGS} element={<BlogListingPage />} />
      <Route path={APP_ROUTES.BLOG_DETAIL} element={<BlogDetailsPage />} />
      <Route path={APP_ROUTES.WEBINAR} element={<WebinarListingPage />} />
      <Route
        path={APP_ROUTES.WEBINAR_DETAIL}
        element={<WebinarDetailsPage />}
      />

      <Route element={<ProtectedRoute />}>
        <Route path={APP_ROUTES.UPDATE_PAYMENT} element={<PaymentPlan />} />
        <Route path={APP_ROUTES.PAYMENT_PLAN} element={<PaymentPlan />} />
        <Route path={APP_ROUTES.CHANGE_PASSWORD} element={<ChangePassword />} />
        <Route path={APP_ROUTES.CHECKOUT} element={<CheckoutPage />} />
        <Route path={APP_ROUTES.UNSUBSCRIBE} element={<UnsubscriptionPage />} />
        <Route
          path={APP_ROUTES.SINGLE_SIGN_ON}
          element={<SignleSignOnPage />}
        />

        <Route path={APP_ROUTES.BASE_URL} element={<DashboardLayout />}>
          <Route
            path={APP_ROUTES.TRANSACTION_HISTORY}
            element={<TransactionHistory />}
          />
          <Route
            path={APP_ROUTES.CUSTOMER_ACCOUNT}
            element={<CustomerAccount />}
          />
          <Route path={APP_ROUTES.CREATE_USER} element={<UserList />} />
          <Route
            path={APP_ROUTES.EMAIL_PREFERENCES}
            element={<EmailPrefrencesPage />}
          />
          <Route path={APP_ROUTES.DASHBOARD} element={<Dashboard />} />
          <Route path={APP_ROUTES.MY_ACCOUNT} element={<MyAccountPage />} />
          <Route
            path={APP_ROUTES.BILLING_DETAILS}
            element={<BillingDetails />}
          />
        </Route>
      </Route>
    </Routes>
  )
}
