import { type FC } from 'react'
import { useTheme } from '@mui/material'
import { Container, Grid, Typography } from '@ntpkunity/controls'
import { Card } from '@shared/components'
import { JourneySectionStyle } from './journeySectionStyle'

export const JourneySection: FC = () => {
  const theme = useTheme()
  return (
    <JourneySectionStyle theme={theme} className='journey-section'>
        <Container theme={theme} maxWidth="lg" className="custom-container">
        <Typography theme={theme} variant='h2' component='h2' className='heading-h2' textAlign={'center'}>
            Our Journey Ahead
            </Typography>
            <Typography theme={theme} variant='h1' component='h1' className='heading-h1' textAlign={'center'} mb={{ md: 6, sm: 4, xs: 4 }}>
                What We&apos;re Building!
            </Typography>
            <Grid theme={theme} container rowSpacing={2} columnSpacing={2} alignItems={'center'}>
                <Grid theme={theme} item sm={6} xs={12}>
                    <Card
                        title='Model Context Protocol'
                        text='Making our APIs LLM friendly via an open-source standard that securely connects AI tools with AppexNow&apos;s products, enabling seamless, real-time context sharing for smarter, more integrated AI applications.'
                    />
                </Grid>
                <Grid theme={theme} item sm={6} xs={12}>
                    <Card
                        title='Multi-Agent System'
                        text='A network of AI agents working together autonomously to solve complex tasks through collaboration, coordination, or negotiation.'
                    />
                </Grid>
                <Grid theme={theme} item sm={6} xs={12}>
                    <Card
                        title='Agentic Flows'
                        text='Agentic auto retail portal for the dealership staff to optimize operations, enhances customer experiences, and enables proactive, data-driven decision-making.'
                    />
                </Grid>
                <Grid theme={theme} item sm={6} xs={12}>
                    <Card
                        title='Digital Auto Retail (Chatbot)'
                        text='Customer facing chatbot designed for auto retail, providing personalized recommendations, education and real-time assistance to delight the car-buying experience.'
                    />
                </Grid>
            </Grid>
            </Container>
          </JourneySectionStyle>
  )
}
